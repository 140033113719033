<template>
  <div>
    <select-shop :loadingButton="loadingButton"></select-shop>
    <div v-if="isPermission">
    <form class="mt-4">
      <CCol md="12" lg="12">
        <CCard class="shadow-sm">
          <CCardBody style="overflow-y: auto">
            <div class="row">
              <div class="col-md-9 col-sm-8 col-8 d-inline-flex align-items-center">
                <h2 class="font-weight-normal text-dark">
                  {{ $t("totalOfImportMembers") }}
                  <span>({{ tableData.length }} {{ $t("item") }})</span>
                </h2>
              </div>
              <div class="col-md-3 col-sm-4 col-4 text-right text-dark">
                <CButton
                  block
                  class="btn btn-outline-success btn-block"
                  @click="goToGoogleSheet()"
                >
                  {{ $t("downloadFileExample") }}
                </CButton>
              </div>
            </div>
            <hr />
            <div v-if="tableData.length > 0" style="overflow-x: auto">
              <ag-grid-vue
                id="importMemberTable"
                class="ag-theme-balham text-dark"
                style="width: 100%; height: 600px"
                :columnDefs="columnDefs"
                :rowData="tableData"
                :rowClassRules="rowClassRules"
                @grid-ready="onGridReady"
              ></ag-grid-vue>
            </div>
            <div v-else style="height: 50%" >
              <label class="upload-container">
                <input
                  id="importMemberFileInput"
                  type="file"
                  @change="handleFileUpload"
                  ref="fileInput"
                  class="file-input"
                />
                <div v-if="!file" class="upload-content">
                  <i class="fi fi-rr-upload upload-icon"></i>
                  <h3 class="mt-2">{{ $t("uploadFile") }}</h3>
                </div>
                <div id="importMemberFileInfo" v-else class="file-info text-center">
                  <p>{{ file.name }}</p>
                </div>
              </label>
            </div>
          <CRow class="justify-content-end mt-3">
            <CCol v-if="file && tableData.length === 0" class="col-3">
              <CButton
                id="importMemberClearButton"
                block
                class="btn btn-outline-success btn-block"
                @click="clearFile"
                :disabled="!addFileCorrect"
              >
                {{ $t("clear") }}
              </CButton>
            </CCol>
            <CCol v-if="file && tableData.length === 0" class="col-3">
              <CButton
                id="importMemberUploadFileButton"
                block
                color="success"
                @click="readFileContent(file)"
                :disabled="!addFileCorrect"
              >
                {{ $t("uploadFile") }}
              </CButton>
            </CCol>
            <CCol v-if="tableData.length > 0" class="col-3">
              <CButton
                id="importMembertConfirmClearButton"
                block
                variant="outline"
                color="success"
                @click="confirmClearModal()"
              >
                {{ $t("clear") }}
              </CButton>
            </CCol>
            <CCol
              v-if="tableData.length > 0 && !saveActionButton && !saveUpdateButton"
              class="col-3"
            >
              <CButton
                id="importMemberVerifyButton"
                block
                color="success"
                @click="checkFile"
                :disabled="isVerifying"
              >
                <CSpinner v-if="isVerifying" color="white" size="sm" />
                {{ isVerifying ? $t("verify") : $t("verify") }}
              </CButton>
            </CCol>
            <CCol v-if="tableData.length > 0 && saveActionButton" class="col-3">
              <CButton
                id="importMemberSaveActionButton"
                block
                color="success"
                @click="openConfirmModal('saveAction')"
                :disabled="isSaving"
              >
                <CSpinner v-if="isSaving" color="white" size="sm" />
                {{ isSaving ? $t("saving") : $t("saveAction") }}
              </CButton>
            </CCol>
            <CCol v-if="tableData.length > 0 && saveUpdateButton" class="col-3">
              <CButton
                id="importMemberSaveUpdateButton"
                block
                color="success"
                @click="openConfirmModal('saveUpdate')"
                :disabled="isSaving"
              >
                <CSpinner v-if="isSaving" color="white" size="sm" />
                {{ isSaving ? $t("saving") : $t("saveUpdate") }}
              </CButton>
            </CCol>
          </CRow>  
          </CCardBody>
          
          <CRow>
            <CModal
              id="importMemberSuccessModal"
              color="success"
              :show.sync="successModal"
              :title="$t('saveSuccess')"
              centered
            >
              <h5 class="text-center mt-3">{{ $t("saveMemberSuccessDialog") }}</h5>
              <h5 class="text-center">{{ $t("saveMemberSuccessDialog2") }}</h5>
              <template #footer>
                <CRow class="justify-content-center col-md-12">
                  <CCol col="5">
                    <CButton
                      id="importMemberDoneButton"
                      block
                      v-if="successButton"
                      color="success"
                      @click="goback()"
                    >
                      {{ $t("done") }}
                    </CButton>
                    <CButton
                      id="importMemberDoneDisabledButton"
                      v-else
                      block
                      color="success"
                      disabled
                    >
                      <CSpinner color="white" size="sm" /> {{ $t("done") }}
                    </CButton>
                  </CCol>
                </CRow>
              </template>
            </CModal>
          </CRow>
        </CCard>
      </CCol>
      <CModal
        id="importMemberConfirmModal"
        color="success"
        :show.sync="confirmModal"
        :title="$t('confirmImportMemberHeader')"
        :style="{ 'max-width': '800px' }"
        centered
      >
        <br />
        <h4 class="text-center">{{ $t("pass") }} {{ totalPass }} {{ $t("item") }}</h4>
        <h4 class="text-center text-danger">
          {{ $t("fail") }} {{ totalFalse }} {{ $t("item") }}
        </h4>
        <h5 class="text-center text-danger">{{ $t("alertImportMemberDialog") }}</h5>
        <h5 v-show="saveUpdateButton" class="text-center text-danger" v-html="$t('alertImportMemberDialog2')"></h5>
        <h5 class="text-center text-danger">{{ $t("confirmImportMember") }}</h5>
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="5" lg="4">
                <CButton
                  id="importMemberCancelButton"
                  block
                  color="light"
                  @click="confirmModal = false"
                >
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            <CCol col="5" lg="4">
              <CButton
                id="importMemberSaveButton"
                block
                v-if="showbuttonsave && !savingInProgress"
                color="success"
                @click="saveFile(selectedActionType)"
              >
                {{ $t("save") }}
              </CButton>
              <CButton
                id="importMemberSaveDisabledButton"
                v-else
                block
                color="success"
                disabled
              >
                <CSpinner color="white" size="sm" /> {{ $t("save") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
      <CRow>
        <CModal
          id="importMemberClearModal"
          color="success"
          :show.sync="clearModal"
          :title="$t('clearTableData')"
          centered
        >
          <h4 class="text-center">{{ $t("clearTableDataDialog") }}</h4>
          <br />
          <h5 class="text-center">{{ $t("clearTableData") }}</h5>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol col="5" lg="4">
                <CButton
                  id="importMemberClearCancelButton"
                  block
                  color="light"
                  @click="clearModal = false"
                >
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
              <CCol col="5" lg="4">
                <CButton
                  id="importMemberClearConfirmButton"
                  block
                  v-if="clearButton"
                  color="success"
                  @click="clearTable()"
                >
                  {{ $t("clear") }}
                </CButton>
                <CButton
                  id="importMemberClearDisabledButton"
                  v-else
                  block
                  color="success"
                  disabled
                >
                  <CSpinner color="white" size="sm" /> {{ $t("clear") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CModal
        id="importMemberInProgressModal"
        color="warning"
        :show.sync="importInProgressModal"
        :title="$t('importMemberWarning')"
        centered
      >
        <div class="text-center mt-3">
          <h5>{{ $t("importMemberWarningDialog") }}</h5>
          <h5>{{ $t("importMemberPleaseWait") }}</h5>
        </div>
        <template #footer>
          <div style="display: flex; justify-content: center; width: 100%">
            <CButton
              id="importMemberSubmitButton"
              style="width: 30%; color: white"
              color="warning"
              @click="clearTable()"
            >
              {{ $t("submit") }}
            </CButton>
          </div>
        </template>
      </CModal>
    </form>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import Papa from "papaparse";
import axios from "@/services/local";
import permis from '@/util/permission'
export default {
  data() {
    return {
      file: null,
      loadingButton: true,
      tableData: [],
      columnDefs: [
        {
          headerName: "#",
          field: "rowIndex",
          valueGetter: "node.rowIndex + 1",
          width: 50,
        },
        { headerName: this.$t("name"), field: "name" },
        { headerName: this.$t("countryCode"), field: "country", width: 200 },
        { headerName: this.$t("tel"), field: "phone" },
        { headerName: this.$t("email"), field: "email" },
        { headerName: this.$t("point"), field: "point" },
        { headerName: this.$t("contactAddress"), field: "contactAddress" },
        { headerName: this.$t("txtTaxAddress"), field: "address" },
        { headerName: this.$t("taxID"), field: "taxID" },
        { headerName: this.$t("saveAs"), field: "actionRow" },
        { headerName: this.$t("reason"), field: "messageshow" },
      ],
      successModal: false,
      confirmModal: false,
      totalPass: 0,
      totalFalse: 0,
      clearModal: false,
      importInProgressModal: false,
      successButton: false,
      showbuttonsave: false,
      clearButton: false,
      isVerifying: false,
      saveUpdateButton: false,
      saveActionButton: false,
      addFileCorrect: false,
      savingInProgress: false,
      gridApi: null,
      isSaving: false,
      confirmSaveModal: false,
      rowClassRules: {
        "red-row": (params) => params.data.pass === false,
        "green-row": (params) => params.data.pass === true,
      },
      selectedActionType: '', 
    };
  },
  components: { AgGridVue },
  computed:{
    isPermission() {
      return permis.findPermissionRead('member', this.$route.path)
    },
    isEdit() {
      return permis.findPermissionEdit('member', this.$route.path)
    },
  },
  methods: {
    openConfirmModal(actionType) {
      this.selectedActionType = actionType;
      this.confirmModal = true;
    },
    clearFile() {
      this.file = null;
      this.$refs.fileInput.value = null;
    },
    confirmClearModal() {
      this.clearModal = true;
      this.clearButton = true;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === "text/csv") {
        this.file = file;
        this.addFileCorrect = true;
        this.readFileContent(file);
      } else {
        alert("Please upload a CSV file.");
      }
    },
    readFileContent(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        Papa.parse(e.target.result, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            this.tableData = result.data.map((row) => ({
              name: row["ชื่อ-นามสกุล (Name)*require"]?.trim() || "",
              country: row["รหัสประเทศ (Country code)"]?.trim() || "TH",
              phone: row["เบอร์โทร (Tel)*require"]?.trim() || "",
              email: row["อีเมล (E-mail)"]?.trim() || "",
              point: row["คะแนน (Point)"] ? parseFloat(row["คะแนน (Point)"].trim()) || "" : "",
              contactAddress: row["ที่อยู่สำหรับติดต่อ (Contact Address)"]?.trim() || "",
              address: row["ที่อยู่สำหรับใบกำกับภาษี (Tax Invoice Address)"]?.trim() || "",
              taxID: row["เลขประจำตัวเสียภาษี ( TAX ID )"]?.trim() || "",
              message: "",
              actionRow: ""
            }));
          },
        });
      };
      reader.readAsText(file);
    },
    checkFile() {
      this.isVerifying = true;
      let url = `/api/v3.0/member/import/validate`;
      const data = {
        shopObjectId: this.$store.getters.shopObjectId,
        rows: this.tableData,
      };
      const params = {shopObjectId: this.$store.getters.shopObjectId,}
      const headers = { "Content-Type": "application/json" , shopObjectId: this.$store.getters.shopObjectId,};

      axios
        .post(url, data, { headers, params })
        .then((response) => {
          if (response.data.error && response.data.error.code != 0) {
            this.importInProgressModal = true;
            this.isVerifying = false;
            return;
          }
          const validatedRows = response.data.data;

          this.tableData = validatedRows.map((row) => {
            let actionRowText = '';

            return {
              ...row,
              actionRow: this.getValidationAction(row.action),
              messageshow: row.pass
                ? this.$t("pass")
                : this.getValidationMessage(row.message),
            };
          });
          const hasUpdate = validatedRows.some(row => row.action === 'update');
          this.totalPass = this.tableData.filter((row) => row.pass).length;
          this.totalFalse = this.tableData.filter((row) => !row.pass).length;
          this.saveActionButton = this.totalPass > 0 && !hasUpdate;
          this.saveUpdateButton = this.totalPass > 0 && hasUpdate;
          this.showbuttonsave = true;
          this.$nextTick(() => {
            if (this.gridApi) {
              this.gridApi.refreshCells({ force: true });
            }
          });

          this.isVerifying = false;
        })
        .catch((error) => {
          console.error("Validation error:", error);
          this.isVerifying = false;
          this.$toast.error(this.$t("validationFailed"));
        });
    },

    saveFile(actionType) {
      this.isSaving = true; 
      this.savingInProgress = true;
      const cleanedRows = this.tableData.filter(row => row.pass === true).map(({ messageshow, actionRow, ...rest }) => rest)

      const payload = {
        shopObjectId: this.$store.getters.shopObjectId,
        action: actionType === "saveUpdate" ? "update" : "create", 
        rows: cleanedRows,
      };
      axios({
        method: "post",
        url: "/api/v3.0/member/import/save", // API Endpoint
        data: payload,
        headers: { "Content-Type": "application/json", shopObjectId: this.$store.getters.shopObjectId, },
      })
        .then((response) => {
          if (response.data.error && response.data.error.code != 0) {
            this.importInProgressModal = true;
            this.isSaving = false;
            this.savingInProgress = true;
            this.successModal = false;
            this.confirmModal = false;
            return;
          }
          this.confirmModal = false
          this.savingInProgress = false;
          this.isSaving = false;
          this.successModal = true;
          this.successButton = true;
          this.$toast.success(this.$t("saveSuccess"));
        })
        .catch((error) => {
          console.error("Save error:", error);
          this.isSaving = false;
          this.$toast.error(this.$t("saveFailed"));
        });
    },
    clearTable() {
      this.tableData = [];
      this.file = null;
      this.clearButton = false;
      this.saveActionButton = false;
      this.saveUpdateButton = false;
      this.clearModal = false;
      this.importInProgressModal = false;
    },
    getValidationMessage(message) {
      switch (message) {
        case "Name is required.":
          return this.$t("nameMemberRequired");
        case "Phone is required.":
          return this.$t("phoneRequired");
        case "Invalid Phone Number.":
          return this.$t("invalidPhone");
        case "Phone already exists. Data will be updated.":
          return this.$t("phoneExistsUpdate");
        case "Invalid Email.":
          return this.$t("emailBadlyFormatted");
        case "Duplicate Phone Number.":
          return this.$t('dupTel')
        case "Wrong Country Code.":
          return this.$t('wrongCountryCode')
        default:
          return this.$t("validationFailed");
      }
    },
    getValidationAction(message) {
      switch (message) {
        case "create":
          return this.$t("createNew");
        case "update":
          return this.$t("updateMember");
        case "skip":
          return this.$t("fail");
        default:
          return this.$t("createNew");
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.refreshTable();
    },
    refreshTable() {
      if (this.gridApi) {
        this.columnDefs = [
          {
            headerName: "#",
            field: "rowIndex",
            valueGetter: "node.rowIndex + 1",
            width: 50,
          },
          { headerName: this.$t("name"), field: "name" },
          { headerName: this.$t("countryCode"), field: "country", width: 100 },
          { headerName: this.$t("tel"), field: "phone" },
          { headerName: this.$t("email"), field: "email" },
          { headerName: this.$t("point"), field: "point" },
          { headerName: this.$t("contactAddress"), field: "contactAddress" },
          { headerName: this.$t("txtTaxAddress"), field: "address" },
          { headerName: this.$t("taxID"), field: "taxID" },
          { headerName: this.$t("saveAs"), field: "actionRow" },
          { headerName: this.$t("reason"), field: "messageshow" },
        ];
      }
      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridApi.refreshCells({ force: true });
    },
    updateMessageShow() {
      this.tableData = this.tableData.map((row) => ({
        ...row,
        actionRow: this.getValidationAction(row.action),
        messageshow: row.pass ? this.$t("pass") : this.getValidationMessage(row.message),
      }));

      // Refresh ag-grid
      this.$nextTick(() => {
        if (this.gridApi) {
          this.gridApi.refreshCells({ force: true });
        }
      });
    },
    goToGoogleSheet() {
      window.open(
        "https://docs.google.com/spreadsheets/d/1Fj8tYEnEI8LdPKNmFN5boqV-u2bomaQBNE9GqIYZvyA/copy?usp=sharing",
        "_blank"
      );
    },
    goback() {
      this.$router.push("/member/data");
    },
  },
  watch: {
    "$i18n.locale": function (newLocale, oldLocale) {
      if (this.gridApi) {
        this.refreshTable();
        this.updateMessageShow();
      }
    },
    shopObjectId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clearTable();
      }
    },
  },
};
</script>

<style>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";

.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  border: 2px dashed #cfcfcf;
  padding: 10%;
  width: 100%;
  height: 60vh;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-icon {
  font-size: 3rem;
  color: #4b5f71;
}

.file-input {
  visibility: hidden;
  position: absolute;
}
.green-cell {
  color: #29b289;
}
.green-row {
  color: #29b289;
}
.pass-row {
  color: #4b5f71;
  /* สีเขียวอ่อน */
}

.red-row {
  color: #ee3e37;
  /* สีเขียวอ่อน */
}
.ag-theme-balham {
  --ag-background-color: #ffffff;
  --ag-foreground-color: #4b5f71;
  --ag-text-color: var(--ag-foreground-color);
  --ag-accent-color: #ffffff;
  --ag-invalid-color: #e02525;
  --ag-border-color: #e5e5e5;
  --ag-wrapper-border: solid 1px var(--ag-border-color);
  --ag-row-border: 10px solid black;
  --ag-header-border: var(--ag-row-border);
  --ag-footer-border: var(--ag-row-border);
  --ag-column-border: solid 1px var(--ag-border-color);
  --ag-column-header-border: var(--ag-column-border);
  --ag-column-header-border-height: 100%;
  --ag-pinned-column-border: solid 1px var(--ag-border-color);
  --ag-pinned-row-border: solid 1px var(--ag-border-color);
  --ag-side-panel-border: solid 1px var(--ag-border-color);
  --ag-side-button-selected-border: solid 1px var(--ag-border-color);
  --ag-side-button-selected-background-color: var(--ag-background-color);
  --ag-side-bar-background-color: var(--ag-chrome-background-color);
  --ag-font-family: sukhumvit;
  --ag-chrome-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 2%
  );
  --ag-header-background-color: #f6f6f6;
  --ag-header-font-family: Inter;
  --ag-header-font-weight: 700;
  --ag-header-font-size: 16px;
  --ag-header-text-color: #4b5f71;
  --ag-header-cell-hover-background-color: transparent;
  --ag-header-cell-hover-background-transition-duration: 0.2s;
  --ag-data-color: #4b5f71;
  --ag-subtle-text-color: color-mix(in srgb, transparent, var(--ag-text-color) 50%);
  --ag-range-selection-border-style: solid;
  --ag-range-selection-border-color: var(--ag-accent-color);
  --ag-range-selection-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-accent-color) 20%
  );
  --ag-range-selection-chart-background-color: #0058ff1a;
  --ag-range-selection-chart-category-background-color: #00ff841a;
  --ag-range-selection-highlight-color: color-mix(
    in srgb,
    transparent,
    var(--ag-accent-color) 50%
  );
  --ag-row-hover-color: color-mix(in srgb, transparent, var(--ag-accent-color) 12%);
  --ag-column-hover-color: color-mix(in srgb, transparent, var(--ag-accent-color) 5%);
  --ag-selected-row-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-accent-color) 8%
  );
  --ag-modal-overlay-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-background-color) 66%
  );
  --ag-odd-row-background-color: #ffffff;
  --ag-border-radius: 0px;
  --ag-wrapper-border-radius: 0px;
  --ag-cell-horizontal-padding: calc(
    var(--ag-grid-size) * 2 * var(--ag-cell-horizontal-padding-scale)
  );
  --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-cell-horizontal-padding-scale: 0.8;
  --ag-label-widget-spacing: var(--ag-grid-size);
  --ag-row-group-indent-size: calc(var(--ag-cell-widget-spacing) + var(--ag-icon-size));
  --ag-value-change-delta-up-color: #43a047;
  --ag-value-change-delta-down-color: #e53935;
  --ag-value-change-value-highlight-background-color: #16a08580;
  --ag-grid-size: 4px;
  --ag-font-size: 16px;
  --ag-row-height: calc(
    max(var(--ag-icon-size), var(--ag-font-size)) + var(--ag-grid-size) * 3.5 *
      var(--ag-row-vertical-padding-scale)
  );
  --ag-row-vertical-padding-scale: 1.5;
  --ag-header-height: calc(
    max(var(--ag-icon-size), var(--ag-font-size)) + var(--ag-grid-size) * 4.25 *
      var(--ag-header-vertical-padding-scale)
  );
  --ag-header-vertical-padding-scale: 1.5;
  --ag-popup-shadow: 0 0 16px 0 #00000026;
  --ag-dropdown-shadow: 0 1px 4px 1px #babfc766;
  --ag-drag-ghost-background-color: var(--ag-background-color);
  --ag-drag-ghost-border: solid 1px var(--ag-border-color);
  --ag-drag-ghost-shadow: var(--ag-popup-shadow);
  --ag-focus-shadow: 0 0 0 3px color-mix(in srgb, transparent, var(--ag-accent-color) 50%);
  --ag-side-bar-panel-width: 250px;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-height: 30%;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-color: var(--ag-border-color);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-horizontal-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-vertical-spacing: var(--ag-grid-size);
  --ag-list-item-height: calc(var(--ag-icon-size) + var(--ag-widget-vertical-spacing));
  --ag-icon-size: 16px;
  --ag-toggle-button-width: 28px;
  --ag-toggle-button-height: 18px;
  --ag-toggle-button-border-width: 2px;
  --ag-toggle-button-on-border-color: var(--ag-accent-color);
  --ag-toggle-button-on-background-color: var(--ag-accent-color);
  --ag-toggle-button-off-border-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 30%
  );
  --ag-toggle-button-off-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 30%
  );
  --ag-toggle-button-switch-border-color: var(--ag-toggle-button-off-border-color);
  --ag-toggle-button-switch-background-color: var(--ag-background-color);
  --ag-checkbox-border-width: 1px;
  --ag-checkbox-border-radius: var(--ag-border-radius);
  --ag-checkbox-unchecked-background-color: var(--ag-background-color);
  --ag-checkbox-unchecked-border-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 30%
  );
  --ag-checkbox-checked-background-color: var(--ag-accent-color);
  --ag-checkbox-checked-border-color: var(--ag-accent-color);
  --ag-checkbox-checked-shape-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%3E%3Cpath%20stroke%3D%22%23000%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.75%22%20d%3D%22M1%203.5%203.5%206l5-5%22%2F%3E%3C%2Fsvg%3E");
  --ag-checkbox-checked-shape-color: var(--ag-background-color);
  --ag-checkbox-indeterminate-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 30%
  );
  --ag-checkbox-indeterminate-border-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 30%
  );
  --ag-checkbox-indeterminate-shape-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%222%22%20fill%3D%22none%22%3E%3Crect%20width%3D%2210%22%20height%3D%222%22%20fill%3D%22%23000%22%20rx%3D%221%22%2F%3E%3C%2Fsvg%3E");
  --ag-checkbox-indeterminate-shape-color: var(--ag-background-color);
  --ag-radio-checked-shape-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226%22%20height%3D%226%22%20fill%3D%22none%22%3E%3Ccircle%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
  --ag-menu-border: solid 1px
    color-mix(in srgb, transparent, var(--ag-foreground-color) 20%);
  --ag-menu-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 3%
  );
  --ag-menu-text-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 95%
  );
  --ag-menu-shadow: var(--ag-popup-shadow);
  --ag-menu-separator-color: var(--ag-border-color);
  --ag-set-filter-indent-size: var(--ag-icon-size);
  --ag-chart-menu-button-border: solid 1px var(--ag-border-color);
  --ag-icon-button-hover-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 10%
  );
  --ag-dialog-shadow: var(--ag-popup-shadow);
  --ag-dialog-border: solid 1px
    color-mix(in srgb, transparent, var(--ag-foreground-color) 20%);
  --ag-panel-background-color: var(--ag-background-color);
  --ag-panel-title-bar-background-color: var(--ag-header-background-color);
  --ag-panel-title-bar-border: solid 1px var(--ag-border-color);
  --ag-column-select-indent-size: var(--ag-icon-size);
  --ag-tool-panel-separator-border: solid 1px var(--ag-border-color);
  --ag-tooltip-background-color: var(--ag-chrome-background-color);
  --ag-tooltip-text-color: var(--ag-text-color);
  --ag-tooltip-border: solid 1px var(--ag-border-color);
  --ag-column-drop-cell-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 7%
  );
  --ag-column-drop-cell-border: solid 1px
    color-mix(in srgb, transparent, var(--ag-foreground-color) 13%);
  --ag-advanced-filter-builder-button-bar-border: solid 1px var(--ag-border-color);
  --ag-advanced-filter-builder-indent-size: calc(
    var(--ag-grid-size) * 2 + var(--ag-icon-size)
  );
  --ag-advanced-filter-builder-join-pill-color: #f08e8d;
  --ag-advanced-filter-builder-column-pill-color: #a6e194;
  --ag-advanced-filter-builder-option-pill-color: #f3c08b;
  --ag-advanced-filter-builder-value-pill-color: #85c0e4;
  --ag-filter-tool-panel-group-indent: calc(var(--ag-grid-size));
  --ag-icon-button-hover-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 10%
  );
  --ag-row-loading-skeleton-effect-color: rgba(66, 66, 66, 0.2);
  --ag-tab-bar-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 5%
  );
  --ag-tab-bar-horizontal-padding: 0;
  --ag-tab-bar-top-padding: 0;
  --ag-tab-background-color: transparent;
  --ag-tab-text-color: color-mix(in srgb, transparent, var(--ag-text-color) 70%);
  --ag-tab-horizontal-padding: calc(var(--ag-grid-size));
  --ag-tab-top-padding: calc(var(--ag-grid-size));
  --ag-tab-bottom-padding: calc(var(--ag-grid-size));
  --ag-tab-spacing: 0;
  --ag-tab-hover-background-color: var(--ag-tab-background-color);
  --ag-tab-hover-text-color: var(--ag-text-color);
  --ag-tab-selected-background-color: var(--ag-background-color);
  --ag-tab-selected-text-color: var(--ag-text-color);
  --ag-tab-selected-border-width: 1px;
  --ag-tab-selected-border-color: var(--ag-border-color);
  --ag-tab-selected-underline-color: transparent;
  --ag-tab-selected-underline-width: 0;
  --ag-tab-selected-underline-transition-duration: 0;
  --ag-tab-bar-border: solid 1px var(--ag-border-color);
  --ag-input-background-color: var(--ag-background-color);
  --ag-input-border: solid 1px var(--ag-border-color);
  --ag-input-border-radius: var(--ag-border-radius);
  --ag-input-text-color: var(--ag-text-color);
  --ag-input-padding-start: var(--ag-grid-size);
  --ag-input-height: calc(
    max(var(--ag-icon-size), var(--ag-font-size)) + var(--ag-grid-size) * 2
  );
  --ag-input-focus-background-color: var(--ag-input-background-color);
  --ag-input-focus-border: solid 1px var(--ag-accent-color);
  --ag-input-focus-shadow: var(--ag-focus-shadow);
  --ag-input-focus-text-color: var(--ag-input-text-color);
  --ag-input-disabled-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 6%
  );
  --ag-input-disabled-border: var(--ag-input-border);
  --ag-input-disabled-text-color: color-mix(
    in srgb,
    transparent,
    var(--ag-text-color) 50%
  );
  --ag-input-invalid-background-color: var(--ag-input-background-color);
  --ag-input-invalid-border: solid 1px var(--ag-invalid-color);
  --ag-input-invalid-text-color: var(--ag-input-text-color);
}

.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
  border-top: 0px;
  border-left: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
}

.ag-header-cell {
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  color: #4b5f71;
}

.ag-header-cell-resize {
  display: none;
}

.ag-header-cell::before,
.ag-header-group-cell:not(.ag-header-span-height.ag-header-group-cell-no-group)::before {
  display: none;
}
</style>
